// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-hu-404-js": () => import("./../../../src/pages/hu/404.js" /* webpackChunkName: "component---src-pages-hu-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lt-404-js": () => import("./../../../src/pages/lt/404.js" /* webpackChunkName: "component---src-pages-lt-404-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-sk-404-js": () => import("./../../../src/pages/sk/404.js" /* webpackChunkName: "component---src-pages-sk-404-js" */),
  "component---src-templates-cms-js": () => import("./../../../src/templates/cms.js" /* webpackChunkName: "component---src-templates-cms-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-landingpage-js": () => import("./../../../src/templates/landingpage.js" /* webpackChunkName: "component---src-templates-landingpage-js" */),
  "component---src-templates-list-advisor-js": () => import("./../../../src/templates/list-advisor.js" /* webpackChunkName: "component---src-templates-list-advisor-js" */),
  "component---src-templates-searchresult-js": () => import("./../../../src/templates/searchresult.js" /* webpackChunkName: "component---src-templates-searchresult-js" */),
  "component---src-templates-single-advisor-js": () => import("./../../../src/templates/single-advisor.js" /* webpackChunkName: "component---src-templates-single-advisor-js" */),
  "component---src-templates-single-partner-js": () => import("./../../../src/templates/single-partner.js" /* webpackChunkName: "component---src-templates-single-partner-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/single-product.js" /* webpackChunkName: "component---src-templates-single-product-js" */),
  "component---src-templates-vp-searchresult-js": () => import("./../../../src/templates/vp-searchresult.js" /* webpackChunkName: "component---src-templates-vp-searchresult-js" */)
}

